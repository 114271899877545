:root {
    --white: #fff;
    --black: #000;
    --puke: #c6e421;
    --frank: #ff7e30;
    --offwhite: #f5f5f5;
}


.about-page {
    padding: 40px 30px;
    color: black;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.about-span {
    font-size: 3rem;

    font-weight: 300 !important;
    margin: auto;
}

.about-link-btn {
    margin-bottom: 2rem;
}

.about-link-btn:hover {
    color: #c6e421;
}

@media screen and (max-width: 1300px) {

    .about-span {
        font-size: 2rem;
    }
}

@media screen and (max-width: 850px) {

    .about-span {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 550px) {

    .about-span {
        font-size: 1rem;
    }
}

@media screen and (max-width: 350px) {

    .about-span {
        font-size: .7rem;
    }
}