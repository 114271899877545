:root {
    --white: #fff;
    --black: #000;
    --puke: #c6e421;
    --frank: #ff7e30;
    --offwhite: #f5f5f5;
    --pinkypink: rgb(206, 157, 157);
    --brownish: rgb(77, 67, 67);
}


header {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.header__button[page="/portfolio"][data-open="true"] {
    background-color: var(--puke);
    animation-name: scale;
}

.header__button[page="/"][data-open="true"] {
    background-color: var(--black);
    animation-name: scale;
}

.header__button[page="/contact"][data-open="true"] {
    background-color: var(--brownish);
    animation-name: scale;
}

.header__button[page="/about"][data-open="true"] {
    background-color: var(--offwhite);
    animation-name: scale;
}

.header__button {
    z-index: 2;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--frank);
    border: 0;
    position: absolute;
    right: 2rem;
    top: 2rem;
    margin: 1rem;
    outline: none;
    animation-duration: 300ms;
    animation-timing-function: ease;
}

/* .header__button[data-open="true"] {
    background-color: #000;
    animation-name: scale;
}  */

.header__button[data-open="false"] {
    -webkit-transition: background-color 50ms linear;
    -moz-transition: background-color 50ms linear;
    -o-transition: background-color 50ms linear;
    transition: background-color 50ms linear;
}

@-webkit-keyframes scale {

    from {
        /* -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0); */
        transform: scale(0);
    }

    to {
        /* -webkit-transform: scale(100%);
        -moz-transform: scale(100%);
        -ms-transform: scale(100%);
        -o-transform: scale(100%); */
        transform: scale(100%);
    }
}

.visited {
    display: inline-block;
    color: var(--pinkypink) !important;
    transform: skew(-30deg) !important;
}

.header__nav {
    background-color: var(--frank);
    position: fixed;
    overflow: hidden;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 3.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: hidden;
    clip-path: circle(var(--radius) at calc(100% - 55px) 47px);
}

.header__nav[data-active="true"] {
    visibility: visible;
}

.header__menu {
    padding: 0;
    margin: 0;
}


.header__menu>.header__menu-item {
    font-size: 7.5rem;
    margin-top: 1rem;
}

.header__menu>.header__menu-item:not(:last-of-type) {
    margin-bottom: 0em;
}

.socials {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
}

.socials-item {
    font-size: 2rem;
    margin: 1.5rem;
}

.socials-item a {

    font-weight: 300 !important;
    color: #000;
    text-decoration: none;
    transition: 0.3s;
}

.header__menu-item a {

    font-weight: 300 !important;
    color: #000;
    text-decoration: none;
    transition: 0.3s;
}

.header__menu-item a:hover,
.socials-item a:hover {
    color: var(--pinkypink);
}


/* LAPTOP */
@media screen and (max-width: 1440px) {

    .socials-item {
        font-size: 1.5rem;
    }

    .header__menu>.header__menu-item {
        font-size: 5rem;
        margin-top: 2rem;
    }
}

/* LAPTOP */
@media screen and (max-width: 1024px) {

    .socials-item {
        font-size: 1.2rem;
    }

    .header__menu>.header__menu-item {
        font-size: 4.5rem;
        margin-top: 1.8rem;
    }
}

/* TABLET */
@media screen and (max-width: 768px) {

    .socials-item {
        font-size: 1rem;
    }

    .header__menu>.header__menu-item {
        font-size: 4rem;
        margin-top: 3rem;
    }
}

/* MOBILE L */
@media screen and (max-width: 425px) {

    .socials-item {
        font-size: 0.75rem;
    }

    .socials {
        left: -20%;
    }

    .header__menu>.header__menu-item {
        font-size: 2rem;
        margin-top: 4rem;
    }
}

/* MOBILE S  */
@media screen and (max-width: 375px) {

    .header__menu>.header__menu-item {
        font-size: 1rem;
        margin-top: 4rem;
    }

    .socials-item {
        font-size: .6rem;
    }
}

/* MOBILE S  */
@media screen and (max-width: 320px) {

    .header__menu>.header__menu-item {
        font-size: 1rem;
        margin-top: 4rem;
    }

    .socials {
        left: -40%;
    }

    .socials-item {
        font-size: .6rem;
    }
}