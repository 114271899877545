:root {
  --white: #fff;
  --black: #000;
  --puke: #c6e421;
  --frank: #ff7e30;
  --offwhite: #f5f5f5;
}

@font-face {
  font-family: akira;
  src: url('./fonts/akira-thicc.ttf');
  /* src: url('./fonts/StretchPro.ttf'); */
}

body {
  margin: 0;
  height: 100vh;
  color: var(--offwhite);
  background-color: var(--black);
  font-family: akira;
  overflow: hidden;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--black);
  text-transform: uppercase;
}