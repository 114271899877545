.landing-page {
    padding: 40px 30px;
    font-size: 1rem;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

h1 {
    font-size: 6rem;
    font-weight: normal;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 1300px) {

    h1 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 850px) {

    h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 550px) {

    h1 {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 350px) {

    h1 {
        font-size: 1.2rem;
    }
}